<template>
  <div
    class="flex-1 flex-col border md:border-0 md:bg-neutral-200"
    :class="
      props.focused ? 'border-transparent bg-neutral-200' : 'border-black '
    "
  >
    <div
      class="flex flex-col gap-1.5 border-b border-black p-6 md:gap-3"
      :class="{
        'bg-green-400': props.highlighted,
      }"
    >
      <h3 class="text-2xl font-bold md:text-2.5xl">
        <slot name="heading" />
      </h3>
      <div class="text-lg font-bold md:text-xl">
        <slot name="price" />
      </div>
    </div>
    <div class="p-6 text-base leading-normal md:text-xl">
      <slot name="description" />
    </div>
  </div>
</template>

<script setup lang="ts">
const props = defineProps<{
  highlighted?: boolean;
  focused?: boolean;
}>();
</script>
